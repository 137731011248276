/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

/* Table */

/* .ui.form .inline.fields .field{
    margin-left: 0.5rem;
}

.ui.checkbox .box:before, .ui.checkbox label:before {

    width: 30px;
    height: 30px;
    margin-top: -5px;
}

.ui.checkbox .box, .ui.checkbox label {
    padding-left: 2.75rem;
}

.ui.form .inline.field>label, .ui.form .inline.field>p, .ui.form .inline.fields .field>label, .ui.form .inline.fields .field>p, .ui.form .inline.fields>label{
    padding-right: 1.5rem;
    font-weight: 500;
}


.ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after{
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    color: #1EBDB9;
}

.ui.form{
    font-size: 0.9rem;
}

.ui.checkbox input.hidden+label{
    font-size: 0.8rem;
    padding-top: 0.4rem;
    font-weight: 300;
} */

.ant-checkbox-wrapper + span, .ant-checkbox + span{
  font-size: 0.85rem;
}

.ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value{
  font-size: 0.85rem;
}

.ant-select-dropdown-menu-item{
  font-size: 0.85rem;
}

/* .ant-radio-checked .ant-radio-inner {
  background-color: white !important;
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 2px !important;
}

.ant-radio-inner {
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 2px !important;
}

.ant-radio {
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
  border-radius: 2px !important;
}

.ant-radio-inner:after{
  left: 11px !important;
  top: 8px !important;
}

.ant-radio-wrapper .ant-radio-inner:after, .ant-radio-wrapper .ant-radio-inner:after{
    -webkit-transform: rotate(45deg) scale(1) !important;
    position: absolute !important;
    display: table !important;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0px;
    border-right-color: #1EBDB9;
    border-right-style: solid;
    border-right-width: 2px;
    border-bottom-color: #1EBDB9;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    width: 5.71428571px !important;
    height: 9.14285714px !important;
    border-top: 0 !important;
    border-left: 0 !important;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s !important;
    opacity: 0;
    border-radius: 0  !important;
    background-color: none !important;
}


.ant-radio-wrapper .ant-radio-inner:after,  .ant-radio-wrapper .ant-radio-inner:after{
  background-color: white !important;
}

.ant-radio-wrapper, .ant-radio-wrapper-checked{
  margin-right: 30px !important;

}

.ant-radio-wrapper, .ant-radio-wrapper{
  align-items: center;
  display: inline-flex !important;
}

.ant-radio-group-item, .ant-radio-item{
  margin-right: 30px !important;
} */

.small-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #e9e9e9 !important;
}

.small-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .large-checkbox .ant-checkbox:hover .ant-checkbox-inner, .large-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner{
  background-color: white !important;
  border-color: #e9e9e9 !important;
}

.small-checkbox .ant-checkbox-inner {
  border-color: #e9e9e9 !important;
}

.small-checkbox .ant-checkbox {
  border-color: #e9e9e9 !important;
}

.small-checkbox .ant-checkbox-checked .ant-checkbox-inner:after{
  border-right-color: #1EBDB9 !important;
  border-bottom-color: #1EBDB9 !important;
}

.large-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: white !important;
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
}

.large-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner, .large-checkbox .ant-checkbox:hover .ant-checkbox-inner, .large-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner{
  background-color: white !important;
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
}

.large-checkbox .ant-checkbox-inner {
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
}

.large-checkbox .ant-checkbox {
  border-color: #e9e9e9 !important;
  height: 30px !important;
  width: 30px !important;
}

.ant-checkbox-wrapper {
  /* margin-right: 30px !important; */
}

.large-checkbox .ant-checkbox-checked .ant-checkbox-inner:after{
  border-right-color: #1EBDB9 !important;
  border-bottom-color: #1EBDB9 !important;
}

.large-checkbox .ant-checkbox-inner:after{
  left: 11px !important;
  top: 8px !important;
}

.ant-checkbox-group-item{
  margin-right: 30px !important;
}

/* .ui.form .field{
    height: 30px;
    font-weight: 400;
} */

.ui.celled.table tr td, .ui.celled.table tr th{
  border: none;
  padding: 10px 10px !important;
}

.ui.table{
  border: none;
  font-size: 0.85rem !important;
}

.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n){
  background-color: #EEEEEE;
}

.ui.sortable.table thead th.sorted{
  background-color: #1EBDB9;
  color: white !important;
}


.ui.sortable.table thead th{
  background-color: #1EBDB9 !important;
  color: white !important;
  white-space: pre-wrap !important;
}

.ui.sortable.table thead th:hover{
  background-color: #17918E !important;
}

/* Buttons */

button.ui.button.greenButton{
    background-color: #1EBDB9;
    color: white;
    border-radius: 25px;
    width: 8rem;
    height: 2rem;
    font-size: 0.9rem ;
    line-height: 0rem;
  }

  button.ui.button.greyButton{
    background-color: #CCCCCC;
    color: white;
    border-radius: 25px;
    width: 8rem;
    height: 2rem;
    font-size: 0.9rem ;
    line-height: 0rem;
  }

.ui.celled.table tr td, .ui.celled.table tr th{
  text-align: center;
}

.portoHeader{
  text-align: right;
}

.ui.tabular.menu{
  font-size: 0.85rem ;
}

.ui.tabular.menu .active.item{
  background: #1EBDB9 !important;
  color: white !important;
  border-radius: 5px 5px 0 0;
  border: none !important;
}

.ui.tabular.menu .item{
  background: #CCCCCC !important;
  color: white !important;
  border-radius: 5px 5px 0 0;
  margin: 0 1px;
  border: none !important;
}

.ui.attached.tabular.menu{
  margin: 0 20px 0 -1px !important;
}

.ui.sortable.table thead th{
  background-color: #dddddd;
}

.ui.table thead th{
  background-color: #1EBDB9;
  color: white;
  border-radius: 0px !important;
}


/* .ant-btn.fullButton{
  width: 100%;
  text-align: center;
} */

/* .ant-btn{
  padding-left: 0;
  padding-right: 0;
  background: #1EBDB9 !important;
  border: none !important;
  border-radius: 500px !important;
  height: 2.1rem !important;
} */


/* .ant-col-12{
  padding: 0px !important;
  margin: 10px 0px !important;
} */

.standard-button{
  min-width: 100px;
  padding-left: 0;
  padding-right: 0;
  background: #1EBDB9 !important;
  border: none !important;
  border-radius: 500px !important;
  height: 2.1rem !important;
  color: white !important;
}

.standard-button-alt{
  min-width: 100px;
  color: #1EBDB9 !important;
  background: white !important;
  border: solid 1px #1EBDB9 !important;
  padding-left: 0;
  padding-right: 0;
  border-radius: 500px !important;
  height: 2.1rem !important;
}

.standard-button-grey{
  min-width: 100px;
  color: white !important;
  background-color: #CCCCCC !important;
  padding-left: 0;
  padding-right: 0;
  border: none !important;
  border-radius: 500px !important;
  height: 2.1rem !important;
}

.standard-button-red{
  min-width: 100px;
  color: white !important;
  background-color: #f64744 !important;
  padding-left: 0;
  padding-right: 0;
  border: none !important;
  border-radius: 500px !important;
  height: 2.1rem !important;
}

.standard-button-margin{
  width: 100px;
  margin: 10px 0 !important;
  padding-left: 0;
  padding-right: 0;
  background: #1EBDB9 !important;
  border: none !important;
  border-radius: 500px !important;
  height: 2.1rem !important;
}

/* Tab */
.ui.attached.tabular.menu{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container{
  margin: 0 9px;
}

.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
  min-width: 100px ;
  text-align: center;
}


.prebook-walkin-tabs.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
  width: 30vw !important;
}
/* Radio */

.ui.radio.checkbox .box:before, .ui.radio.checkbox label:before{
  border-radius: .21428571rem;
  width: 30px;
  height: 30px;
}

/* Container */

.ui.segment{
    padding: 2rem;
}

.pageContainer{
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.ant-row > div{
    margin-bottom: 16px!important;
}

/* Divider */

.ui.divider{
    margin: 2em 0;
}


/* Sidebar */

.leqrtB .isomorphicSidebar .isoLogoWrapper{
  background-color: rgb(241, 243, 246) !important;
}

.leqrtB .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected {
  background-color: rgb(241, 243, 246) !important;
}

.leqrtB .isomorphicSidebar .isoLogoWrapper h3 a {
  color: black !important;
}

/* .hnSbzj .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected{
  background-color: #17918E !important;
}

.hnSbzj .isomorphicSidebar{
  background: #1EBDB9 !important;
}



.hnSbzj .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline > li:not(.ant-menu-item-group), .hnSbzj .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-inline > li:not(.ant-menu-item-group), .hnSbzj .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-submenu-vertical > li:not(.ant-menu-item-group), .hnSbzj .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-submenu-vertical > li:not(.ant-menu-item-group){
    background-color: #1BA7A3 !important;
} */

.leqrtB .isomorphicSidebar .isoDashboardMenu .ant-menu-item-active .span.isoMenuHolder {
  color: black !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title .span {
 color: rgb(0, 0, 0) !important;
}

.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open .ant-menu-submenu-title .icon {
 color: rgb(0, 0, 0) !important;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title span {
 color: rgb(0, 0, 0) !important;
}

.leqrtB .isomorphicSidebar .isoDashboardMenu > li:hover i, .leqrtB .isomorphicSidebar .isoDashboardMenu > li:hover .nav-text {
  color: rgb(0, 0, 0) !important;
}
/*
.ant-menu-item.ant-menu-item-selected:nth-child(1) > a:nth-child(1) {
 color: rgb(255, 255, 255);
} */

.leqrtB .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected .nav-text {
    color: black !important;
}

.leqrtB .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected i {
    color: black !important;
}

.leqrtB .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected {
    background-color: #ddd !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-sub, .leqrtB .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-sub {
  background-color: #eeeeee !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-sub li, .leqrtB .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-sub li {
  background-color: #eeeeee !important;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a {
  color: #777777 !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline > li:not(.ant-menu-item-group):hover a{
  color: #777777 !important;
  transition: none !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline > li:not(.ant-menu-item-group):hover a, .leqrtB .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-inline > li:not(.ant-menu-item-group):hover a, .leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-submenu-vertical > li:not(.ant-menu-item-group):hover a {
  color: #777777 !important;
  transition: none !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-vertical .ant-menu-submenu-vertical > li:not(.ant-menu-item-group):hover a {
  color: #777777 !important;
  transition: none !important;
}

.leqrtB .isomorphicSidebar .ant-menu-submenu-inline .ant-menu-inline > li:hover span{
  color: #777777 !important;
  transition: none !important;
}

span.isoMenuHolder {
  color: black !important;
}


/* Div */

div.ant-col-xs-24 ant-col-sm-12 ant-col-md-12{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.box-border-top{
  border-top: solid 1px #1EBDB9 ;
}

/* div.ant-row{
  margin-left: -8px !important;
  margin-right: -8px !important;
} */

/* Header */

.form-header{
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-subheader{
  color: #777777 !important;
  font-weight: 400 !important;
}

.social-login{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Font */


h4{
  font-size: 0.95rem;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: #1B344C !important;
}

h5{
  font-size: 0.85rem;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: #1B344C !important;
}

h3{
  font-size: 1.2rem !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: #1B344C;
}



p, input, textarea{
  font-size: 0.85rem;
  font-family: Roboto !important;
}

input{
  /* height: 2.1rem !important; */
}

.ant-col-6, .ant-col-24 {
  font-weight: 500;
  color: #1B344C;
}

.smallReminder{
  font-size: 0.7rem !important;
  color: #777777;
}

.smallReminderUnderlineLink{
  text-decoration: underline;
  color: #777777;
}


.page-header{
  margin: 40px 10px 40px;
}
.signin-header{
  margin-left:auto;
  margin-right:auto;
}

.thin-title{
  font-weight: 300 !important;
  font-size: 0.9rem !important;
}

.thin-content{
  font-weight: 300 !important;
  font-size: 0.85rem !important;
  color: #1B344C !important;
}

.download-link{
  color: #1B344C !important;
  text-decoration: underline;
}

.carparkinfo-title{
  margin: 20px 0 !important;
  margin-top: 30px !important;
  text-decoration: underline;
}

/* Form */
.ant-input-group{
  margin-bottom: 1.5rem !important;
}
.ant-input-group-unset-bottom {
    margin-bottom: 0 !important;
}
/*
.ant-layout, .ant-layout *{
  border-radius: 15px !important;
} */

.ant-input-disabled{
  background: #eee !important;
}

.col-with-input-button{
  display: flex !important;
  align-items: flex-end !important;
}


/* Text Area */
textarea{
  height: 10rem !important;
}

.textarea-address{
  height: 7rem !important;
}

.ant-select-selection--multiple{
  /* height: 20rem !important; */
}


/* Popup */
.popupTrigger{
  color: #1EBDB9;
  margin-left: 5px;
}

.ui.top.right.popup.transition.visible.popupContent{
  color: #777777 !important;
  font-size: 0.85rem !important;
}

.ui.top.left.popup.transition.visible.popupContent{
  color: #777777 !important;
  font-size: 0.85rem !important;
  margin-left: -10px;
}

.info-list{
  margin-bottom: 1.5rem !important;
}

/* Tab */

.ant-tabs-bar{
  margin: 0!important;
  border-bottom: 0px!important;
}

.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab{
  background: #CCCCCC !important;
  color: white !important;
  border-color: #CCCCCC !important;
  font-size: 0.85rem;
  margin-right: 3px !important;
}

.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active{
  background: #1EBDB9 !important;
  color: white !important;
  border-color: #1EBDB9 !important;
}

.ant-tabs:not(.ant-tabs-vertical) > .ant-tabs-content{
  margin-left: 8px;
}

/* Menu */

.isomorphicSidebar .isoDashboardMenu .isoMenuHolder i{
  margin: 0 30px 20px 0 !important;
}

/* .hnSbzj .isomorphicSidebar .isoDashboardMenu .nav-text{
  color: #eee !important;
} */


/* Modal */

.ant-modal-close-x, .ant-modal-header, .ant-modal-footer{
  display: none !important;
}

.ant-modal{
  width: 50% !important;
}

.modal2 .ant-modal-header, .modal2 .ant-modal-footer{
  display: block !important;
  text-align: center !important;
}

.modal2.ant-modal-body{
  display: block !important;
  text-align: center !important;
}

.modal2.ant-modal-header, .modal2.ant-modal-footer{
  justify-content: center !important;
}

/* Tooltip */

.tooltipBtn{
  color: #1B344C !important;
}


/* Login Page */
.page-title{
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  color: #1EBDB9;
  font-weight: 500 !important;
  font-size: 2.6rem !important;
}

.page-subtitle{
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  color: #1B344C;
}

.page-subtitle-text{
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  color: #1B344C;
  font-weight: 300 !important;
}
.page-remarks{
  color: #1B344C;
  font-size: 1rem;
  max-width: 25rem;
  text-align: center;
}

.card-title-green{
  text-align:center;
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  color: #1EBDB9;
  font-size: 1.5rem;
  font-weight: 600 !important;
}

.card-title-darkgreen{
  text-align:center;
  margin: 30px;
  margin-left: auto;
  margin-right: auto;
  color: #17918E !important;
  font-size: 1.5rem;
  font-weight: 600 !important;
}

.green-link{
  color: #1EBDB9;
}

.green-link:hover{
  color: #17918E;
}

.flex-row{
  display: flex !important;
  justify-content: center !important;
  text-align: center !important;
  flex-wrap: wrap !important;
}

.powered-by{
  color: #CCCCCC !important;
}

.login-big-button{
  border: solid 1px #e9e9e9;
  border-radius: 5px;
  padding: 40px;
  width: 13rem;
  margin: 10px;
}

.login-big-button:hover{
  background: #f9f9f9 !important;
}

/* Login Page: Register */

.input-underline{
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-radius: 0px !important;
  border-bottom-color: #CCCCCC !important;
  color: #1B344C;
}

.square-button-white{
  color: #1EBDB9 !important;
  background: white !important;
  border-color: #1EBDB9 !important;
  height: 26px !important;
  border-radius: 0px !important;
}

.square-button-green{
  color: white !important;
  background: #1EBDB9 !important;
  border-color: #1EBDB9 !important;
  height: 30px !important;
  border-radius: 5px !important;
  padding: 0 45px !important;
}

.social-media-login-text{
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  color: white !important;
  font-weight: 500 !important;
}


/* Image Uploader */
.upload_button.fileContainer{
  /* background: #f9f9f9 !important; */

}

.fileContainer .chooseFileButton{
  box-shadow: none !important;
  margin: 0 !important;
  padding: 10 30 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.fileUploader{
  display: flex;
  /* justify-content: flex-end; */
}


.fileContainer{
  /* background: #f9f9f9 !important; */
  box-shadow: none !important;
  margin: 5px  0 !important;
  padding: 0 !important;
}

/* Validation */
.invalid-feedback{
  color: red;
}

/* Top Bar */
.top-bar-text{
  font-size: 0.85rem;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: #1B344C !important;
  margin: 10px;
}

/* Col */
.form-field-top-align{
  margin-top: 15px !important;
  display: flex;
  align-items: top;
}

/* Switch */
button.ant-switch {
    border-radius: 20px !important;
}

